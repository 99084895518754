import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";

import "../../scss/main.scss";
import BecomeACarer from "../components/BecomeACarer";


export default function Home() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Home</title>
			</Helmet>
			{/*   ========== Nav ==========  */}
			<div className="container">
				<Header />
				<main>
					<div className="grid-container">
						<div className="landing-img">
							<StaticImage placeholder="blurred"
								src="../../static/assets/marketing-site/images/home-image-combined.png"
								alt="Landing Page Image"
								loading="eager"
								placeholder="blurred"
							/>
						</div>
						<div className="landing-context">
							<h1>High quality care in the comfort of your own home.</h1>
							<p className="primary">
								Edyn provides professional carers, support teams and thoughtful
								technology to provide a 24/7 managed care service for your
								family.
							</p>
							<Link to="/cta" className="button-link">
								<button className="btn-primary wider">Learn more</button>
							</Link>
						</div>
						<div className="area-input">
							<div className="input-field-postcode">
								<div className="postcode-background">
									<div className="postcode-form">
										<p className="secondary">Start your care journey today:</p>
										<div className="post-input-custom">
											<div>
												<input
													className="default"
													type="text"
													id="postalcode"
													name="postcode"
													placeholder="Enter your postcode"
												/>
											</div>
											<div>
												<Link to="/locations">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/searchbtn.svg" />
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="postcode-footer">
									<div className="call-us">
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/postcodeimg1.svg"
											alt="advisors-img"
											loading="eager"
											placeholder="blurred"
										/>
										<div className="call-us-details">
											<p className="secondary">Or call us today on:</p>
											<Link to="tel:020 3970 9900">
												<h4 className="number">020 3970 9900</h4>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="landing-img-mobile">
							<StaticImage placeholder="blurred"
								src="../../static/assets/marketing-site/images/home-image-combined-compact.png"
								alt=""
							/>
						</div>
					</div>

					{/*   ============ WE CARE ====================  */}

					<section className="we-care">
						<StaticImage placeholder="blurred"
							className="img-side we-care__img-left"
							src="../../static/assets/marketing-site/images/we-care-image-1.jpg"
						/>
						<StaticImage placeholder="blurred"
							className="img-side we-care__img-right"
							src="../../static/assets/marketing-site/images/we-care-image-2.jpg"
							loading="eager"
							placeholder="blurred"
						/>
						<div className="card">
							<div className="img">
								<StaticImage placeholder="blurred"
									src="../../static/assets/marketing-site/images/we-care.png"
									alt="We care image"
								/>
							</div>
							<div className="we-care-content">
								<h1>We truly care for our clients</h1>
								<p className="primary">
									Every Edyn customer has a dedicated care team on hand to help
									throughout the entire process, with updates and online
									summaries that help them understand their loved ones’ care and
									wellness.
								</p>
								<Link to="/approach">
									<button className="btn-primary">Our approach</button>
								</Link>
							</div>
						</div>
					</section>
					{/*   ============ WE CARE ====================  */}

					{/*   ============ Why live Divider =========== */}
					<section className="why-live-divider">
						<div className="why-live-section">
							<h3>Why live-in care is the best option for your family:</h3>
							<div className="benefits">
								<div className="benefit">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/check-mark.png"
										alt="checkmark01"
									/>
									<p className="secondary">More affordable than a care home</p>
								</div>
								<div className="benefit">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/check-mark.png"
										alt="checkmark02"
									/>
									<p className="secondary">
										Remain in the comfort of your home
									</p>
								</div>
								<div className="benefit">
									<StaticImage placeholder="blurred"
										className="icon-default"
										src="../../static/assets/marketing-site/images/check-mark.png"
										alt="checkmark03"
									/>
									<p className="secondary">Better health and wellbeing</p>
								</div>
							</div>
						</div>
					</section>
					{/*   ============ /Why live divider =========== */}

					{/*   ============ Why live Section =========== */}
					<section className="live-in">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
						</div>
						<button className="btn-primary">
							Learn more about our process
						</button>
					</section>

					<section className="why-live-section">
						<div className="why-live-quote">
							<div className="why-live-quote-content">
								<div className="wlq-content-container">
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/ian-quote-quotation-mark.png"
										alt="Ian Smith Picture"
										className="quotation-mark-mobile"
										objectFit="contain"
									/>
									<p className="primary ian-quote">
										<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/ian-quote-quotation-mark.png"
										alt="Ian Smith Picture"
										className="quotation-mark-desktop"
										objectFit="contain"
									/>
										Care homes used to be the default for individuals who are
										in need of care, but now 99% of those in their later years
										can stay at home with regulated live-in care. Edyn’s service
										is changing care”
									</p>
									<div className="ian-smith">
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/ian_smith.png"
											alt="Ian Smith Picture"
											objectFit="contain"
										/>
										<p>
											Ian Smith
											<br />
											Former Chair of Four Seasons Care Group
										</p>
									</div>
								</div>
							</div>

							<div className="divider-try-live">
								<BecomeACarer className="compact" backgroundColor="#F8F8EF" title="Become an Edyn carer" description="We’re looking for compassionate, positive and personable individuals to join our team of carers. Vetting and training provided." contact="Join today"/>
							</div>
						</div>
					</section>
					{/*   ============ /Why live Section =========== */}

					{/*   ============ Twenty First Cent Section =========== */}
					<section className="twenty-first-cent">
						<div className="intro">
							<div className="left-block">
								<h2>A care company fit for the 21st century.</h2>
								<p className="primary">
									Alongside our care team we have developed a first-class
									digital experience to help you oversee and stay up to date
									with your care.
								</p>
								<div className="checklist">
									<ul>
										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/check-mark.png"
											/>
											<p className="secondary">Easy to read care plan</p>
										</li>
										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/check-mark.png"
											/>
											<p className="secondary">
												Daily carer notes keeping you informed
											</p>
										</li>
										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/check-mark.png"
											/>
											<p className="secondary">
												Direct contact with your care management team
											</p>
										</li>
										<li>
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/check-mark.png"
											/>
											<p className="secondary">
												Track your loved ones health and wellness
											</p>
										</li>
									</ul>
								</div>
								<Link to="">
									<button className="btn-primary">
										Learn more about our technology
									</button>
								</Link>
							</div>
							<div className="right-block">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cellphone.png" objectFit='contain' className="desktop_view"/>
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cellphone_mobile.png" objectFit='contain' className="mobile_only"/>
							</div>
						</div>
					</section>

					<section className="timeline">
						<div className="things-changed">
							<div className="past">
								<div className="left-block">
									<p className="secondary sub-heading">
										How things have changed
									</p>
									<h2>In the past seniors had to settle with care homes</h2>
									<div className="checklist">
										<ul>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/icon-wrong.png"
													objectFit="contain"
												/>
												<p>No 1:1 care and support</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/icon-wrong.png"
													objectFit="contain"
												/>
												<p>No understanding of wellbeing</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/icon-wrong.png"
													objectFit="contain"
												/>
												<p>Limited family interaction</p>
											</li>
										</ul>
										<div className="time-scroller mobile">
											<div className="background-shadow-fade">
												<h1>1990</h1>
											</div>
										</div>
									</div>
								</div>
								<div className="time-scroller desktop">
									<div className="background-shadow-fade">
										<h1>1990</h1>
									</div>
								</div>
								<div className="right-block">
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/img-1990.png"
										alt=""
										className="desktop_view"
									/>
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/img-1990-mobile.png"
										alt=""
										className="mobile_view"
									/>
								</div>
							</div>
							{/*   2021  */}
							<div className="today">
								<div className="left-block">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/today.png" className="desktop_view"/>
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/today-mobile.png" className="mobile_view"/>
								</div>
								<div className="time-scroller desktop">
									<div className="background-shadow-fade">
										<h1>2020</h1>
									</div>
								</div>
								<div className="right-block">
									<h2>
										Now, with regulated live-in care you can stay in the comfort
										of your home.
									</h2>
									<div className="checklist">
										<ul>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
												/>
												<p className="secondary">Comfort of your own home</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
												/>
												<p className="secondary">Dedicated 1:1 care team</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
												/>
												<p className="secondary">
													Focus on health and wellness
												</p>
											</li>
											<li>
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/check-mark.png"
												/>
												<p className="secondary">Family can stay anytime</p>
											</li>
										</ul>
									</div>
									<div className="time-scroller mobile-none">
										<div className="background-shadow-fade">
											<h1>2020</h1>
										</div>
									</div>
									<Link to="">
										<button className="btn-primary">
											Get started with Edyn
										</button>
									</Link>
								</div>
							</div>
						</div>
					</section>
					{/*   ============ Twenty First Cent Section =========== */}

					{/*   Trust pilot embed goes here  */}
					<section>
						<div className="trust pilot"> </div>
					</section>

					<section>
						<div className="tp-divider">
							<div className="tp-left">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cqc-approved.svg" objectFit="contain" />
								<p className="secondary">
									Edyn Care is approved and regulated by the Care Quality
									commission
								</p>
							</div>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thegaurdian.png"
								alt="the Gaurdian"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thetimes.png"
								alt="the times"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/theeveningstandard.png"
								alt="evening standard"
								objectFit="contain"
							/>
						</div>
					</section>
				</main>{" "}
			</div>
			{/*  end of container */}

			<Footer />
		</>
	);
}
