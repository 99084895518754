import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

import './becomeacarer.scss';

const BecomeACarer = (props) => {
    return(
        <section className={props.className}>
            <div className="become-a-carer" style={{backgroundColor: props.backgroundColor}}>
                <div className="become-carer-left">
                    <StaticImage placeholder="blurred"
                        className="become-carer-people"
                        src="../../../static/assets/marketing-site/images/become-carer-people.png"
                        alt="Carer Image"
                    />
                </div>
                <div className="become-carer-right">
                    <h3 className="heading">{props.title}</h3>
                    <h3 className="heading_mobile">{props.mobileTitle}</h3>
                    <p className="primary middle">
                        {props.description}
                    </p>
                    <p className="primary contact-detail">
                        <span className="contact-detail-cta">{props.cta}</span> 
                        {props.contact ? 
                            <span className="contact-number"> {props.contact}</span> : ' '
                        }
                    </p>
                </div>
            </div>
        </section>
    )
}
 
export default BecomeACarer;